.EventInfo {
    background: var(--bg-dark);
}

.event-logo-wrapper {
    background-color: var(--bg-dark);
    padding: 20px 0 0 0;
    width: fit-content;
}

.lsm2025-wrapper{
    margin-top: 50px;
    position: absolute;
    right: 40px;
}

.lsm2025-wrapper-mobile{
    display: flex;
    justify-content: center;
    display: none;
}

.lsm2025{
    background: linear-gradient(to right, #3a8bac, #195d7a);;
    color: #fff;
    width: fit-content !important;
    padding: 10px 45px;
    border-radius: 13px;
    text-decoration: none;
    font-size: 18px;
    font-family: 'DMSans-Bold';
    cursor: pointer;
}

.event-logo {
    width: 30% !important;
}

.event-info-content-wrapper {
    background-color: var(--bg-dark);
    color: #9d9e9e;
}

.event-info-content-wrapper .sub-text {
    font-family: 'DMSans-Bold';
    font-size: 30px;
}

.sub-para {
    font-size: 14px;
}

.content-para {
    font-size: 14px;
    text-align: justify;
}

.event-heading-tag {
    font-family: 'DMSans-Bold';
    font-size: 20px;
    letter-spacing: 1px;
}

.event-hr {
    background: #9d9e9e;
}

.features-card {
    background-color: #9d9e9e;
    padding: 8px 10px;
    color: var(--bg-dark);
    font-family: 'DMSans-Bold';
}

.convention-aims-section {
    text-align: left;
    padding: 15px 0;
    border: solid 1px #9d9e9e;
}

.convention-aims-section label {
    font-family: 'DMSans-Bold';
}

.who-can-register-section {
    text-align: justify;
    background: #9d9e9e;
    color: #222222;
    padding: 20px !important;
}

.who-can-register-section label {
    font-family: 'DMSans-Bold';
    font-size: 17px;
}

.who-can-register-section p,
.pre-convention-workshop-section p {
    font-size: 16px;
}

.how-does-it-work {
    text-align: justify;
    background: #9d9e9e;
    color: #222222;
    padding: 20px !important;
}

.how-does-it-work label {
    font-family: 'DMSans-Bold';
    font-size: 17px;
}

.how-does-it-work p,
.pre-convention-workshop-section p {
    font-size: 16px;
}

.pre-convention-workshop-section {
    text-align: left;
    background: #9d9e9e;
    color: #222222;
    padding: 20px !important;
}

.pre-convention-workshop-section label {
    font-family: 'DMSans-Bold';
    font-size: 17px;
}

.tag {
    display: inline-block;
    font-family: 'DMSans-Bold';
    font-size: 18px;
    width: auto;
    height: 38px;

    background-color: #222222;
    -webkit-border-radius: 3px 4px 4px 3px;
    -moz-border-radius: 3px 4px 4px 3px;
    border-radius: 3px 4px 4px 3px;

    border-left: 1px solid #979797;

    /* This makes room for the triangle */
    margin-left: 19px;

    position: relative;

    color: #9d9e9e;
    font-weight: 300;
    line-height: 38px;

    padding: 0 10px 0 10px;
}

.tag-striked {
    font-size: 13px;
}

/* Makes the triangle */
.tag:before {
    content: "";
    position: absolute;
    display: block;
    left: -18px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-right: 19px solid #222222;
}

/* Makes the circle */
.tag:after {
    content: "";
    background-color: #222222;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    display: block;
    position: absolute;
    left: -9px;
    top: 17px;
}


/* Testimonials Component */
.testimonials-title {
    font-family: 'DMSans-Bold';
    font-size: 25px;
    color: var(--text-secondary);
    width: fit-content;
}

.testimonial-item {
    transition: 0.3s;
    border-radius: 3px;
    cursor: pointer;
    background-color: #9d9e9e;
    color: var(--text-primary);
    padding: 20px 15px;
    box-shadow: none !important;
}

.testimonial-avatar {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    filter: grayscale(0.3);
}

.testimonial-title {
    font-family: 'DMSans-Bold';
    font-size: 20px;
    margin-bottom: 2px !important;
}

.testimonial-designation {
    font-family: 'DMSans-Medium';
    font-size: 12px;
    color: var(--text-tertiary);
    margin-top: 0 !important;
}

.testimonial-desc {
    font-size: 15px;
    font-family: 'DMSans-Regular';
    color: var(--text-primary);
    margin: 15px 0 0 0 !important;
    text-align: justify;
}

.testimonial-date {
    font-family: 'DMSans-Medium';
    font-size: 12px;
    color: var(--text-tertiary);
}

.testimonial-content {
    text-align: left;
}

.invite-text {
    text-align: justify;
}

.event-tab-item {
    border: solid 1px #9d9e9e;
    color: var(--theme-seondary);
    font-family: 'DMSans-Bold';
    padding: 7px 12px;
    cursor: pointer;
}

.event-tab-item.active {
    color: var(--bg-dark);
    background-color: #9d9e9e;
}

.faq-section {
    text-align: left;
}

.faq-question {
    font-family: 'DMSans-Bold';
}

.fee {
    padding: 15px;
    border: solid 1px #9d9e9e;
}

.fee-detail-section {}

.fee-detail-item {
    background: #9d9e9e;
    color: #222222;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 10px;
}

.early-bird-reg {
    font-family: 'DMSans-Bold';
    font-size: 25px;
}

.due-date {
    font-family: 'DMSans-Bold';
}

.student-discount-label {
    font-family: 'DMSans-Bold';
    text-transform: uppercase;
    font-size: 18px;
}

.discount {
    width: 100%;
}

.discount-price-striked {
    font-size: 14px;
    margin-left: 15px;
}

.discount-price-striked label {
    text-decoration: line-through;
    color: #8b0000;
}

.special-discount {
    text-transform: uppercase;
}

.discount-info {
    width: 100%;
    text-align: left
}

.discount-info p {
    font-size: 13px;
}

.fee-header {
    background: #9d9e9e;
    color: #222222;
    padding: 10px 0px;
    font-family: 'DMSans-Bold';
    margin-bottom: 20px;
}

.fee-footer {
    background: #9d9e9e;
    color: #222222;
    padding: 10px 0px;
    font-family: 'DMSans-Bold';
    margin-top: 20px !important;
}

.event-info-image-layout {
    width: 70% !important;
}

.sub-head-text {
    font-size: 14px;
}

.nearest-attractions {
    border: solid 1px #979797;
}

.attraction-item {
    text-align: left;
}

.attraction-item h5 {
    font-family: 'DMSans-Bold';
    margin-left: 10px;
}

.gallery-img {
    width: 100%;
    height: 100%;
}

.attraction-item-gallery {
    margin: auto !important;
}

.attraction-item-gallery-item {
    margin-top: 10px;
}

.contact-impact {
    font-family: 'DMSans-Bold';
    font-size: 18px;
}

iframe {
    height: 480px;
}

.recap-title {
    font-size: 30px;
}

.images img {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .event-logo {
        width: 60% !important;
    }

    .event-info-content-wrapper .sub-text {
        font-size: 20px;
    }

    .early-bird-reg {
        text-align: left;
    }

    .due-date {
        text-align: left !important;
    }

    .special-discount {
        font-size: 21px;
    }

    .event-info-image-layout {
        width: 100% !important;
    }

    iframe {
        height: 300px;
    }
}

@media (max-width: 990px){
    .lsm2025-wrapper{
        display: none;
    }

    .lsm2025-wrapper-mobile{
        display: flex;
    }
}