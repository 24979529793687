.Overview {
  &__line {
    height: 4px;
    background-color: var(--lsm-blue);
    margin: 10px 0;
  }

  &__title {
    font-family: "Godber";
    font-size: 40px;
    color: var(--lsm-blue);
  }

  &__lsm2023 {
    margin: 30px;

    span {
      background: var(--bg-dark);
      color: gainsboro;
      width: fit-content !important;
      padding: 10px 45px;
      border-radius: 13px;
      text-decoration: none;
      font-size: 18px;
      font-family: "DMSans-Bold";
      cursor: pointer;
    }
    span:hover {
      transform: scale(1.01);
      box-shadow: 0px 8px 16px var(--bg-dark);
    }
  }

  &__aim {
    text-align: center;
    padding: 15px 0;
    color: white;

    &_statements {
      display: flex;
      gap: 10px;
      padding: 0 10px;
      flex-wrap: wrap;

      &_vision {
        background: #fff;
        padding: 15px;
        color: var(--lsm-blue);
        border: solid 5px var(--lsm-blue);
        border-radius: 5px;
        flex: 0.5;

        &_title {
          font-family: "Godber";
          font-size: 50px;
          color: var(--lsm-blue);
        }

        &_wrapper {
          display: flex;

          section {
            text-align: center;
            padding: 0 5px;
          }
        }
      }

      &_about {
        background: #fff;
        padding: 15px;
        color: var(--lsm-blue);
        border: solid 5px var(--lsm-blue);
        border-radius: 5px;
        flex: 0.5;

        &_title {
          font-family: "Godber";
          font-size: 50px;
          color: var(--lsm-blue);
        }
      }

      &_lottie {
        width: 50%;
        margin: auto;
      }

      &_hands {
        width: 36%;
        margin: auto;
      }
    }
  }

  &__features {
    background: linear-gradient(to right, #3a8bac, #195d7a);
    margin: 15px 0;
    color: white;
    padding: 20px 15px;

    div {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      justify-content: center;
    }

    &_title {
      font-family: "Godber";
      font-size: 40px;
      color: #fff;
      margin-bottom: 20px;
    }

    &_date {
      font-size: 35px;
      font-family: "Godber";
    }

    &_item {
      text-align: center;
      display: flex;
      flex-direction: column;
      border: solid 1px white;
      border-radius: 2px;
      width: 300px;
      padding: 8px 0;
    }
  }

  &__steps {
    margin: 15px 0;
    color: var(--lsm-blue);
    padding: 0 15px;
    text-align: left;

    ul {
      margin-bottom: 0;
    }
  }

  &__stepsEligibility {
    margin: 40px 0;
    display: flex;
    flex-wrap: wrap;

    section {
      border-right: solid 2px var(--lsm-blue);
      flex: 0.5;
    }
  }

  &__contact {
    background: linear-gradient(to right, #3a8bac, #195d7a);
    margin-top: 15px;
    color: white;
    padding: 20px 0;
    font-family: "DMSans-Bold";
  }
}

@media (max-width: 1000px) {
  .Overview {
    &__aim {
      &_statements {
        &_vision {
          flex: 100%;
        }

        &_about {
          flex: 100%;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .Overview {
    &__aim {
      &_statements {
        &_vision {
          &_wrapper {
            display: block;
          }
        }
      }
    }

    &__stepsEligibility {
      section {
        flex: 100%;
        border-right: none;
      }
    }
  }
}
