.App {
  overflow-x: hidden;
}

.load-more,
.contact-us,
.pay-register-btn {
  background-color: var(--theme-primary);
  color: var(--text-secondary);
  width: fit-content;
  font-family: "DMSans-Bold";
  font-size: 14px;
  padding: 6px 20px;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 0 10px 3px var(--theme-primary);
}

.btn-effects {
  background-color: #3a8bac;
}

.btn-effects:hover {
  transform: scale(1.01);
  background-color: #195d7a;
  box-shadow: 0px 8px 16px #3a8bac;
}

.btn-effects:active {
  background-color: #3a8bac;
}

.status {
  padding: 6px;
  border-radius: 50%;
}

.status-green {
  background-color: #0cc078;
}

.status-yellow {
  background-color: #ffff76;
}

.status-red {
  background-color: #fb6962;
}

.MuiTypography-root {
  font-family: "DMSans-Bold" !important;
}

.MuiDialogContentText-root {
  font-family: "DMSans-Regular" !important;
  letter-spacing: 0 !important;
}