.Home {
    position: relative;
    color: white;
    height: 100vh;
    background-image: url("../../assets//Images/creation_1.png");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    &__banner {
        &_lsm {
            padding: 15px 20px;
            text-align: center;
            margin: auto;
            width: 45%;
            position: relative;
            display: flex;
            flex-direction: column;

            img {
                width: 100%;
                cursor: pointer;
            }

            button {
                background: linear-gradient(to right, #3a8bac, #195d7a);
                color: white;
                font-family: 'DMSans-Bold';
                padding: 5px 0;
                border: none;
                border-radius: 2px;
            }
        }

        &_text {
            font-family: 'Montserrat-Bold';
            font-size: 35px;
            text-align: center;
            margin: 15px auto
        }

        &_playIcon {
            width: 35px;
            height: 35px;
            cursor: pointer;
        }

        &_logo {
            position: absolute;
            bottom: 15px;
            left: 5px;
            width: 15%;
        }

        &_description {
            font-family: 'Inter-SemiBold';
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 18px;
            margin: 20px auto
        }

        &_subText {
            font-family: 'Montserrat-Bold';
            cursor: pointer;
            margin-bottom: 0;
        }
    }
}

@media (max-width: 1250px) {
    .Home {
        &__banner {
            &_text {
                margin: 0 auto
            }

            &_description {
                margin: 15px
            }
        }
    }
}

@media (max-width: 720px) {
    .Home {
        &__banner {
            &_text {
                margin: 0 auto;
                font-size: 25px;
                text-align: center;
            }

            &_lsm {
                width: 100%;
            }

            padding: 0 20px;
        }
    }
}