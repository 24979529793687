body {
  margin: 0;

  @font-face {
    font-family: Montserrat-Bold;
    src: url(assets/fonts/Montserrat/Montserrat-Bold.ttf);
  }

  @font-face {
    font-family: Inter-SemiBold;
    src: url(assets/fonts/Inter/Inter-SemiBold.ttf);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  font-family: "DMSans-Regular" !important;
  background-color: var(--bg-primary);
}

/* Global CSS Configurations */
:root {
  --overlay-default: #000000;
  --text-primary: #000000;
  --text-secondary: #ffffff;
  --text-tertiary: #6a6a6a;
  --bg-primary: #ffffff;
  --bg-dark: #222222;
  --theme-primary: #3a8bac;
  // --theme-primary: #3a8bac;
  --color-success: #46894b;
  --lsm-blue: #3a8bac;
  --lsm-text: #82766e;
}

/* ScrollBar Config For Body */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Hide number field arrows globally */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number field arrows globally */
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(44, 44, 44);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bg-dark);
  border-radius: 10px;
}

/* Global Font Mapping */
@font-face {
  font-family: "DMSans-Regular";
  src: url("./assets/fonts/DM_Sans/DMSans-Regular.ttf");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("./assets/fonts/DM_Sans/DMSans-Medium.ttf");
}

@font-face {
  font-family: "DMSans-Bold";
  src: url("./assets/fonts/DM_Sans/DMSans-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-Thin";
  src: url("./assets/fonts/Montserrat/Montserrat-Thin.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/fonts/Inter/Inter-Regular.ttf");
}

// LSM font mapping

@font-face {
  font-family: "Godber";
  src: url("./assets/fonts/Lsm/Godber-Regular.ttf");
}

@font-face {
  font-family: "Pangram-Bold";
  src: url("./assets/fonts/Lsm/Pangram-Bold.otf");
}

@font-face {
  font-family: "Pangram-Regular";
  src: url("./assets/fonts/Lsm/Pangram-Regular.otf");
}

@font-face {
  font-family: "Pangram-Medium";
  src: url("./assets/fonts/Lsm/Pangram-Medium.otf");
}
