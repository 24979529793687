.Events {
    font-family: 'DMSans-Regular';
    background-color: var(--bg-primary);
    padding: 40px 20px;
}

.Events h4 {
    font-family: 'DMSans-Bold';
}

.event-item-wrapper {
    padding: 0 10px;
}

.event-item {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 3px;
    cursor: pointer;
    height: 100%;
}

.event-item:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}


.event-img {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 3px;
}

.event-title {
    font-family: 'DMSans-Bold';
    font-size: 16px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.event-desc {
    font-family: 'DMSans-Regular';
    font-size: 13px;
    color: var(--text-tertiary);
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.event-month {
    color: var(--theme-primary);
    font-family: 'DMSans-Bold';
    font-size: 13px;
    letter-spacing: 1px;
}

.event-date {
    font-family: 'DMSans-Bold';
    font-size: 28px;
}

.event-year {
    font-family: 'DMSans-Bold';
    font-size: 13px;
    color: var(--text-tertiary);
}

.load-more {
    background-color: var(--theme-primary);
    color: var(--text-secondary);
    width: fit-content;
    font-family: 'DMSans-Bold';
    font-size: 14px;
    padding: 6px 20px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0 0 20px 10px rgba(252, 82, 82, 0.2);
}