.Registration {
  &__advisory {
    border: solid 2px var(--lsm-blue);
    margin: auto;
    font-size: 20px;
    padding: 15px 30px;
    font-family: "DMSans-Bold";
    background: linear-gradient(to right, #3a8bac, #195d7a);
    color: white;
  }

  &__details {
    padding: 0 30px;
    &_title {
      font-family: "Godber";
      font-size: 40px;
      color: var(--lsm-blue);
      margin: 20px auto;
    }
    &_guidelines {
      color: var(--lsm-blue);
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      margin: 30px;
      div {
        flex: 50%;
        padding: 0 25px;

        a {
          color: var(--lsm-blue);
        }
      }
      div:first-of-type {
        border-right: solid 2px var(--lsm-blue);
      }
    }
    &_fee {
      display: flex;
      flex-wrap: wrap;
      margin: 30px 0;
      color: #fff;
      gap: 25px;

      div {
        flex: 30%;
        padding: 10px 5px;
        background: linear-gradient(to right, #3a8bac, #195d7a);

        h4 {
          font-family: "Godber";
          font-size: 25px;
        }
        span {
          font-size: 16px;
        }
      }
    }
    &_btn {
      background: linear-gradient(to bottom, #3a8bac, #195d7a);
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 9px 25px;
      color: var(--text-secondary);
      font-family: "DMSans-Bold";
      border-radius: 11px;
      font-size: 18px;
      cursor: pointer;
      margin: 20px auto;
    }
    &_contact {
      color: var(--lsm-blue);
      font-family: "DMSans-Medium";
      margin-bottom: 30px;
      padding: 0 50px;
    }
  }
}

@media (max-width: 900px) {
  .Registration {
    &__details {
      &_guidelines {
        display: block;
        margin: 0;

        div:first-of-type {
          border-right: none;
        }
        div {
          padding: 0;
        }
      }
      &_fee {
        div {
          flex: 100%;
        }
      }
      &_contact {
        padding: 0;
      }
    }
  }
}
