.ImageSlider {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0;
}


.images-container {
    display: flex;
    max-width: inherit;
    overflow: hidden;
    /* Add the following lines for smooth scrolling */
    scroll-behavior: smooth;
    transition: scroll 0.3s ease-in-out;
}

.image {
    width: 375px;
    height: 250px;
    animation: move 1s linear infinite;
}

.image:last-of-type {
    margin-right: 0;
}

.nav-btn {
    position: absolute;
    color: white;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: grey;
    height: 30px;
    width: 30px;
    margin: 5px;
    opacity: 0.9;
}

.nav-btn-left {
    left: 0;
}

.nav-btn-right {
    right: 0;
}

@media (max-width: 550px) {
    .image {
        width: 100%;
    }
}