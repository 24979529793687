.Lsm2025 {
  position: relative;
  object-fit: contain;
  height: 100vh;
  padding-top: 100px;
  text-align: center;
  color: var(--lsm-text);

  &__registerBtn {
    background: linear-gradient(to bottom, #3a8bac, #195d7a);
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 9px 25px;
    color: var(--text-secondary);
    font-family: "DMSans-Bold";
    border-radius: 11px;
    font-size: 18px;
    cursor: pointer;
    margin: 20px auto;
  }

  &__head {
    position: relative;

    &_particles {
      position: absolute;
      z-index: -1;
      width: 100%;
    }
  }

  &__logo {
    width: 25%;
  }

  &__title {
    margin: 30px;
    color: var(--lsm-text);
  }

  &__invite {
    margin: 30px 15px;
    color: var(--lsm-text);
  }

  &__tabs {
    color: var(--lsm-blue);
    justify-content: center;

    &_item {
      border: solid 1px var(--lsm-blue);
      font-family: "DMSans-Bold";
      padding: 7px 12px;
      cursor: pointer;
    }

    &_active {
      @extend .Lsm2025__tabs_item;
      background: linear-gradient(to right, #3a8bac, #195d7a);
      color: #fff;
    }
  }
}

@media (max-width: 920px) {
  .Lsm2025 {
    &__logo {
      width: 45%;
    }
  }
}

@media (max-width: 550px) {
  .Lsm2025 {
    &__logo {
      width: 60%;
    }
    &__registerBtn {
      font-size: 16px;
    }
  }
}
