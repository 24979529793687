.Museed {
    &__banner {
        background-color: var(--bg-dark);
        color: var(--text-secondary);
        padding-top: 75px;
        padding-bottom: 30px;
        margin-bottom: 30px;

        &_title {
            font-family: 'Montserrat-Thin';
            width: fit-content;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 50px;
            margin: 0 auto;
        }
    }

    &__content {
        padding: 0 25px;

        &_pics {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            img {
                width: 25%;
                border-radius: 3%;
            }
        }
    }
}