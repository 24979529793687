.Register {}

.event-banner {
    background-color: var(--bg-dark);
    color: var(--text-secondary);
    padding-top: 75px;
    padding-bottom: 30px;
}

.event-banner-title {
    font-family: 'Montserrat-Thin';
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 50px;
    margin-top: 30px;

}

.register-icon {
    width: 20px;
    height: 20px;
    margin-left: 7px;
}

.brand-name {
    width: fit-content;
}

.desc-section,
.register-section,
.registered-message-section {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    transition: 0.3s;
    padding: 20px 10px;
    border-radius: 3px;
    justify-content: center;
    font-family: 'DMSans-Regular';
}

.register-section {
    background: var(--bg-dark);
}

.desc-section {
    box-shadow: none !important;
}

.registered-message-section {
    background:linear-gradient(to right, #3a8bac, #195d7a);;
    color: #FFFFFF;
    text-align: justify;
    padding: 20px 30px;
}

.registered-message-section img {
    width: 13%;
}

.register-events-toggle-btn {
    font-size: 15px;
    background: var(--theme-primary);
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: var(--text-secondary);
    font-family: 'DMSans-Bold';
    border-radius: 3px;
    cursor: pointer;
}

.register-events-toggle-btn:hover {
    transform: scale(1.02);
    background-color: #3a8bac;
    box-shadow: 0px 8px 16px #195d7a;
}

.register-events-toggle-btn:active {
    background-color: #195d7a;
}

.register-verify-email-btn {
    background-color: var(--theme-primary);
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--text-secondary);
    font-family: 'DMSans-Bold';
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
}

.vacant-status-section {
    padding: 20px 10px;
}

.MuiCheckbox-root {
    margin-bottom: 4px !important;
}

.checkbox-label {
    color: var(--text-tertiary);
}

.timer-label {
    color: var(--theme-primary)
}

.resend-otp-label {
    text-align: left;
}

.resend-otp-label label {
    color: var(--theme-primary);
    text-decoration: underline !important;
    cursor: pointer;
}

.pay-register-btn {
    padding: 10px 60px;
    font-size: 14px;
    text-align: center;
}

.MuiFormControlLabel-label {
    font-family: 'DMSans-Regular' !important;
    color: var(--text-tertiary);
}

.bank-criterion-section {
    font-size: 14px;
    color: var(--text-tertiary);
    border: solid 1px var(--text-primary);
}

.bank-logo {
    width: 100%;
}

.bank-logo-wrapper {
    width: 55% !important;
}

.register-warn-label {
    color: #9d9e9e;
    font-size: 14px;
}

.checkbox-label {
    font-size: 25px;
    color: var(--text-primary);
    font-family: 'DMSans-Bold';
}

.checkbox-label-sub-text {
    font-size: 15px;
    color: var(--text-tertiary);
}

.discount-wrapper {
    align-items: baseline;
}

.discountt {
    padding: 0 10px 0 0 !important;
}

.login-href:hover {
    cursor: pointer;
}

/* Media Query for screen adoption */
@media only screen and (max-width: 600px) {

    .registered-message-section img {
        width: 30%;
    }

    .register-events-toggle-btn {
        box-shadow: 0 0 20px 10px rgba(94, 131, 131, 0.3);
    }

    .bank-logo-wrapper {
        margin: auto !important;
    }

}

@media (min-width: 700px) and (max-width: 800px) {

    .registered-message-section img {
        width: 15%;
    }

    .bank-logo-wrapper {
        margin: auto !important;
    }
}