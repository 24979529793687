.Faq {
    text-align: left;
    background: linear-gradient(to right, #3a8bac, #195d7a);
    padding: 30px;
    color: white;

    div {
        border: solid 1px white;
        padding: 15px;
        margin-bottom: 15px;
    }

    &__question {
        font-family: "DMSans-Bold";
        margin: 0;
    }
}