.logo {
    width: 20%;
}

.Navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.8) !important;
}

.Navbar .dropdown-item:active{
    background: var(--theme-primary);
}

.nav-link,
.navbar-brand {
    color: var(--text-secondary) !important;
    text-transform: uppercase;
    font-family: 'Montserrat-Medium';
    font-size: 14px;
}

.navbar {
    background-color: rgba(0, 0, 0, 0);
}

.brand-name {
    font-family: 'Montserrat-Bold';
    font-size: 40px !important;
    cursor: pointer;
}

.brand-name-left-wrapper {
    display: none !important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../../assets/Images/hamburger.png') !important;
    width: 40px;
    height: 40px;
}

.logout-nav-item {
    display: none !important;
}

.logout-icon {
    position: absolute;
    right: 30px;
    cursor: pointer;
   color: #fff;
   text-transform: uppercase;
   letter-spacing: 1px;

}

/* customization for Mobile Screens */
@media only screen and (max-width: 600px) {
    .logo {
        width: 45%;
    }

    .brand-name-center-wrapper {
        display: none !important;
    }

    .brand-name-left-wrapper {
        display: block !important;
    }

    .brand-wrapper {
        width: 100% !important;
    }

    .brand-name-mob {
        width: 60%;
        margin-bottom: 3px;
    }

    .logout-nav-item {
        display: block !important;
    }

    .logout-icon {
        display: none !important;
    }
}