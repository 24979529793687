.c5 {
  &__content {
    img {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
    }

    &_text {
      margin: 30px 0;
      padding: 0 20px;

      h4 {
        margin: auto;
        width: fit-content;
        text-transform: uppercase;
        font-family: "Montserrat-Thin";
        font-size: 45px;
      }
    }
  }
}
